import Swiper, { Autoplay, Pagination, Navigation } from 'swiper';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
import { CountUp } from 'countup.js';
import { Modal } from 'bootstrap';

function init3QVideo(video) {
   const config = JSON.parse(decodeURI(video.dataset.threeq))
   const playerId = 'video-3q-' + Date.now()

   video.setAttribute('id', playerId)
   new js3q({
      dataid: config.playoutId,
      container: playerId,
      cast: false,
      key: config.playoutKey,
      timestamp: Date.now()
   })
}

// Mobile Height Hack
const mobileHeight = () => {
   let vh = window.innerHeight * 0.01;
   document.documentElement.style.setProperty('--vh', `${vh}px`);
}

mobileHeight();

// Masonry Download Grid

const gridDownload = document.querySelector('.grid-download');

if (gridDownload) {
   imagesLoaded(gridDownload, function () {
      // init Isotope after all images have loaded
      new Masonry(gridDownload, {
         itemSelector: '.grid-download-item',
         columnWidth: '.grid-download-sizer',
         transitionDuration: 0,
         percentPosition: true
      })
   })
}


// Swiper Gallery
const swiperGallery = new Swiper(".swiper-gallery", {
   modules: [Autoplay, Pagination, Navigation],
   spaceBetween: 30,
   autoHeight: true,
   loop: true,
   allowTouchMove: true,
   slidesPerView: "auto",
   centeredSlides: true,

   pagination: {
      el: ".swiper-teaser-pagination",
      clickable: true,
   },
   navigation: {
      nextEl: '.swiper-teaser-next',
      prevEl: '.swiper-teaser-prev',
   },
   keyboard: {
      enabled: true,
      onlyInViewport: true,
   },
   a11y: {
      prevSlideMessage: 'Einen Slide zurück',
      nextSlideMessage: 'Einen Slide vor',
   }
})

// Swiper Teaser Big
const swiperTeaserBig = new Swiper(".swiper-teaser-big", {
   modules: [Autoplay, Pagination, Navigation],
   slidesPerView: 1,
   spaceBetween: 30,
   // autoHeight: true,
   loop: true,
   allowTouchMove: true,

   breakpoints: {
      768: {
         slidesPerView: 2
      }
   },
   pagination: {
      el: ".swiper-teaser-pagination",
      clickable: true,
   },
   navigation: {
      nextEl: '.swiper-teaser-next',
      prevEl: '.swiper-teaser-prev',
   },
})

// Swiper Teaser Highlight
const swiperTeaserHighlight = new Swiper(".swiper-teaser-highlight", {
   modules: [Autoplay, Pagination, Navigation],
   slidesPerView: 1,
   spaceBetween: 30,
   autoHeight: true,
   loop: true,
   allowTouchMove: true,

   pagination: {
      el: ".swiper-teaser-pagination",
      clickable: true,
   },
   navigation: {
      nextEl: '.swiper-teaser-next',
      prevEl: '.swiper-teaser-prev',
   },
})

// Swiper Teaser Header
const swiperTeaserHeader = new Swiper(".swiper-teaser-header", {
   modules: [Autoplay, Pagination, Navigation],
   slidesPerView: 1,
   spaceBetween: 30,
   autoHeight: true,
   loop: true,
   allowTouchMove: true,

   pagination: {
      el: ".swiper-teaser-header-pagination",
      clickable: true,
   },
   navigation: {
      nextEl: '.swiper-teaser-header-next',
      prevEl: '.swiper-teaser-header-prev',
   },
})

// Swiper Teaser Content
const swiperContent = new Swiper(".swiper-content", {
   modules: [Autoplay, Pagination, Navigation],
   slidesPerView: 1,
   spaceBetween: 30,
   autoHeight: true,
   loop: true,
   allowTouchMove: true,

   pagination: {
      el: ".swiper-teaser-pagination",
      clickable: true,
   },
   navigation: {
      nextEl: '.swiper-teaser-next',
      prevEl: '.swiper-teaser-prev',
   },
})


// ScrollToTop
const scrollToTop = document.querySelector('.side-totop');

if (scrollToTop) {
   scrollToTop.addEventListener('click', () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
   })

   window.onscroll = function () { scrollFunction() };

   function scrollFunction() {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
         scrollToTop.classList.add('show');
      } else {
         scrollToTop.classList.remove('show');
      }
   }
}


// Countupjs Teaser Number

const counterTeaserNumbers = document.querySelectorAll(".teaser-number");

if (counterTeaserNumbers) {
   counterTeaserNumbers.forEach(function (counterTeaserNumber) {

      const counterTeaserNumberOption = {
         separator: ".",
         duration: 2.5,
      };

      const counterTeaserNumberOptionDecimal = {
         decimalPlaces: 2,
         decimal: ',',
         separator: ".",
         duration: 2.5,
      };

      let counterTeaserNumberItem = counterTeaserNumber.querySelector(".teaser-number__number");
      let counterTeaserNumberData = counterTeaserNumberItem.dataset.number;
      let counterTeaserNumberDataDecimal = counterTeaserNumberItem.dataset.mode;
      let countUp = new CountUp(counterTeaserNumberItem, counterTeaserNumberData, counterTeaserNumberOption);

      const observeOptions = {
         root: null,
         threshold: 0.75,
      };

      if (counterTeaserNumberDataDecimal == "decimal") {
         let countUpDezimal = new CountUp(counterTeaserNumberItem, counterTeaserNumberData, counterTeaserNumberOptionDecimal);

         const observerTeaserNumberDecimal = new IntersectionObserver(function (entries, observer) {
            entries.forEach((entry) => {
               if (!entry.isIntersecting) {
                  return;
               } else {
                  countUpDezimal.start();
                  observerTeaserNumberDecimal.unobserve(entry.target);
               }
            });
         }, observeOptions);

         observerTeaserNumberDecimal.observe(counterTeaserNumber);
      } else {
         const observerTeaserNumber = new IntersectionObserver(function (entries, observer) {
            entries.forEach((entry) => {
               if (!entry.isIntersecting) {
                  return;
               } else {
                  countUp.start();
                  observerTeaserNumber.unobserve(entry.target);
               }
            });
         }, observeOptions);

         observerTeaserNumber.observe(counterTeaserNumber);
      }
   });
}


// Navigation Mobile

const navToggle = document.getElementById("navToggle");
const navigation = document.getElementById("navigation");
const navOverlay = document.getElementById("navOverlay");
const body = document.body;

function openNavigation() {
   body.classList.add('overflow');
   navToggle.classList.add('navheader__toggle--active');
   navToggle.setAttribute('aria-expanded', 'true');
   navigation.classList.add('navigation--active');
   navOverlay.classList.add('navigation-overlay--active');
}

function closeNavigation() {
   body.classList.remove('overflow');
   navToggle.classList.remove('navheader__toggle--active');
   navToggle.setAttribute('aria-expanded', 'false');
   navigation.classList.remove('navigation--active');
   navOverlay.classList.remove('navigation-overlay--active');
}

if (navToggle) {
   navToggle.addEventListener('click', () => {
      if (navigation.classList.contains('navigation--active')) {
         closeNavigation();
      } else {
         openNavigation();
      }
   });

   navOverlay.addEventListener('click', () => {
      closeNavigation();
   });
}

const navSublists = document.querySelectorAll('.navigation__sublist');

navSublists.forEach(function (navSublist) {
   const navMore = navSublist.parentNode.children[1];
   const navSublistParent = navSublist.parentNode;

   navMore.addEventListener('click', () => {
      if (navSublistParent.classList.contains('navigation__item--active')) {
         navSublistParent.classList.toggle('navigation__item--toggle-active');
      } else {
         navSublistParent.classList.toggle('navigation__item--toggle');
      };
   });
});

const navHeader = document.querySelector(".navheader");

if (navHeader) {
   window.onscroll = () => scrollNavigation();

   function scrollNavigation() {
      if (document.body.scrollTop > 140 || document.documentElement.scrollTop > 140) {
         navHeader.classList.add('navheader--scroll');
      } else {
         navHeader.classList.remove('navheader--scroll');
      }
   }
}

function initLoadMoreBtn(button, url = '?', success = () => { }, fail = () => { }) {
   button.addEventListener('click', () => {
      if (!button.classList.contains('btn-loader--spinning')) {
         button.classList.add('btn-loader--spinning')
         const page = parseInt(button.dataset.page)

         fetch(url + 'page=' + page, {
            headers: {
               'X-Requested-With': 'XMLHttpRequest'
            }
         })
            .then(response => {
               if (response.status >= 200 && response.status < 400) {
                  return response.json()
               }

               return response.json()
                  .then(json => Promise.reject(json))
            })
            .then(response => {
               button.dataset.page = page + 1

               success(response)
            })
            .catch(error => {
               fail(error)
            })
            .finally(() => {
               button.classList.remove('btn-loader--spinning')
            })
      }
   })
}

// More Article Button

const btnMoreArticles = document.querySelectorAll('.btn-more-articles')

if (btnMoreArticles) {
   const url = location.search.length ? location.search + '&' : '?'

   btnMoreArticles.forEach(button => {
      initLoadMoreBtn(button, url,
         response => {
            const articleTeasers = document.querySelector('#article-teasers .teaser-container')
            articleTeasers.innerHTML += response.teasers

            if (response.hasMoreArticles === false) {
               button.style.display = 'none'
            }
         },
         error => {
            console.log(error)
         }
      )
   })
}

// More Search Results Button

const btnMoreResults = document.getElementById('btn-more-results')

if (btnMoreResults) {
   const url = location.search.length ? location.search + '&' : '?'

   initLoadMoreBtn(btnMoreResults, url,
      response => {
         const existingDownloadBts = Array.from(document.querySelectorAll('.download-file-btn'))
         const existingVideos = Array.from(document.querySelectorAll('[data-threeq]'))

         const resultList = document.getElementById('search-result-list')
         resultList.innerHTML += response.results

         const newDownloadBtns = document.querySelectorAll('.download-file-btn')

         newDownloadBtns.forEach(newDownloadBtn => {
            if (!existingDownloadBts.includes(newDownloadBtn)) {
               newDownloadBtn.addEventListener('click', downloadAction)
            }
         })

         const newVideos = document.querySelectorAll('[data-threeq]')

         newVideos.forEach(newVideo => {
            if (!existingVideos.includes(newVideo)) {
               init3QVideo(newVideo)
            }
         })

         if (response.hasMoreResults === false) {
            btnMoreResults.style.display = 'none'
         }
      },
      error => {
         console.log(error)
      }
   )
}

// File download

const downloadBtn = document.getElementById('article-zip-btn')

if (downloadBtn) {
   downloadBtn.addEventListener('click', function () {
      let blob
      let filename
      const progressBar = document.getElementById('zip-progress')
      const xmlHTTP = new XMLHttpRequest()
      const url = downloadBtn.href

      downloadBtn.disabled = true
      downloadBtn.querySelector('.spinner-border').classList.remove('d-none')

      progressBar.parentElement.classList.remove('d-none')

      xmlHTTP.open('GET', '', true)
      xmlHTTP.responseType = 'arraybuffer'
      xmlHTTP.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

      xmlHTTP.onload = function (event) {
         blob = new Blob([this.response]);
         const contentDispo = event.currentTarget.getResponseHeader('Content-Disposition');
         filename = contentDispo.match(/filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/)[1];
      }

      xmlHTTP.onprogress = function (event) {
         let progress = (event.loaded / event.total) * 100
         if (progress === Infinity) {
            progress = 100
         }
         const progrssFixed = progress.toFixed(0)

         progressBar.setAttribute('aria-valuenow', progrssFixed)
         progressBar.style.width = progrssFixed + '%'
         progressBar.innerText = progrssFixed + '%'
      }

      xmlHTTP.onloadend = function () {
         const url = window.URL.createObjectURL(blob)
         const tempEl = document.createElement("a")

         tempEl.style = "display: none"
         tempEl.href = url
         tempEl.download = filename

         document.body.appendChild(tempEl)

         tempEl.click()
         tempEl.remove()

         window.URL.revokeObjectURL(url)

         progressBar.setAttribute('aria-valuenow', 0)
         progressBar.style.width = 0 + '%'
         progressBar.innerText = 0 + '%'

         downloadBtn.disabled = false
         downloadBtn.querySelector('.spinner-border').classList.add('d-none')
         const modal = Modal.getInstance(document.getElementById('zipModal'))
         modal.hide()
      }

      xmlHTTP.send();
   })
}

const downloadBtns = document.querySelectorAll('.download-file-btn')

downloadBtns.forEach(downloadBtn => {
   downloadBtn.addEventListener('click', downloadAction)
})

const myModal = new Modal(document.getElementById('downloadModal'), {
   backdrop: 'static',
   keyboard: false
})

function downloadAction(event) {
   event.preventDefault()
   const downloadBtn = this

   let blob
   let filename
   const progressBar = document.getElementById('download-progress')
   const fileid = downloadBtn.dataset.file
   const width = downloadBtn.dataset.width
   let url = downloadBtn.href + '?file=' + fileid

   if (width) {
      url += '&width=' + width
   }

   const xmlHTTP = new XMLHttpRequest()

   downloadBtn.disabled = true

   myModal.show()

   xmlHTTP.open('POST', url, true)
   xmlHTTP.responseType = 'arraybuffer'
   xmlHTTP.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

   xmlHTTP.onload = function (event) {
      blob = new Blob([this.response]);
      const contentDispo = event.currentTarget.getResponseHeader('Content-Disposition');
      filename = contentDispo.match(/filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/)[1];
   }

   xmlHTTP.onprogress = function (event) {
      console.log(event)

      let progress = (event.loaded / event.total) * 100
      if (progress === Infinity) {
         progress = 100
      }
      const progrssFixed = progress.toFixed(0)

      progressBar.setAttribute('aria-valuenow', progrssFixed)
      progressBar.style.width = progrssFixed + '%'
      progressBar.innerText = progrssFixed + '%'
   }

   xmlHTTP.onloadend = function () {
      const url = window.URL.createObjectURL(blob)
      const tempEl = document.createElement("a")

      myModal.hide()

      tempEl.style = "display: none"
      tempEl.href = url
      tempEl.download = filename

      document.body.appendChild(tempEl)

      tempEl.click()
      tempEl.remove()

      window.URL.revokeObjectURL(url)

      progressBar.setAttribute('aria-valuenow', 0)
      progressBar.style.width = 0 + '%'
      progressBar.innerText = 0 + '%'

      downloadBtn.disabled = false
   }

   xmlHTTP.send();
}
