window.addEventListener('load', function () {
    const queryinput = document.getElementById('queryinput')
    const searchbar = document.getElementById('searchbar')
    const searchform = document.getElementById('searchform')

    const searchfield = searchbar.querySelector('.navheader__searchfield')
    const searchsuggests = searchbar.querySelector('.navheader__searchsuggests')

    let typetimeout = null

    const closeSuggests = function() {
        searchsuggests.classList.remove('navheader__searchsuggests--open')
        searchfield.classList.remove('navheader__searchfield--open')
    }

    const openSuggests = function() {
        searchsuggests.classList.add('navheader__searchsuggests--open')
        searchfield.classList.add('navheader__searchfield--open')
    }

    document.addEventListener('click', () => {
        closeSuggests()
    })

    queryinput.addEventListener('input', function() {
        const query = queryinput.value
        clearTimeout(typetimeout)

        if(query.length >= 3) {
            typetimeout = setTimeout(() => {
                fetch(searchform.action + '?typeahead=1&q=' + query, {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                })
                .then(response => {
                    if(response.status >= 200 && response.status < 400) {
                        return response.json()
                    }
    
                    return response.json().then(response => Promise.reject(response))
                })
                .then(options => {
                    if(options.length) {
                        searchsuggests.innerHTML = '<li>' + options.join('</li><li>') + '</li>'

                        searchsuggests.querySelectorAll('li').forEach(item => {
                            item.setAttribute('tabindex', 0)
                            
                            item.addEventListener('click', event => {
                                event.stopPropagation()

                                queryinput.value = item.innerText
                                searchform.submit()
                            })
                        })

                        openSuggests()
                    }
                    else {
                        closeSuggests()
                        searchsuggests.innerHTML = null
                    }
                })
                .catch(error => console.log(error))
            }, 150);
        }
        else {
            closeSuggests()
        }
    })
}, false);
